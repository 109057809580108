const teams = [{
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726861339/yashbahiram_drakuv.jpg",
        name: "Yash Bahiram",
        Post: "Secretary",
        l: "",
        i: "",
        m: "mailto: "
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1727334131/vedika_xdtxg8.jpg",
        name: "Vedika Bharmal",
        Post: "Secretary",
        l: "",
        i: "",
        m: "mailto: "
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726293436/Kalpesh_Deshmukh___Chief_Coordinator__iwdwx7.jpg",
        name: "Kalpesh Deshmukh",
        Post: "Chief Coordinator",
        l: "",
        i: "",
        m: "mailto: "
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1727334047/Shrutika_Rode_Chief_Coordinator_ioeg2e.jpg",
        name: "Shrutika Rode",
        Post: "Chief Coordinator",
        l: "",
        i: "",
        m: "mailto: "
    },

    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1727333929/Sai_Sonawane_Treasure_vavoso.jpg",
        name: "Sai Sonawane",
        Post: "Treasurer",
        l: "",
        i: "",
        m: "mailto: "
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1727333871/Vedant_Bhosale_Treasurer__bbrsoj.jpg",
        name: "Vedant Bhosale",
        Post: "Treasurer",
        l: "",
        i: "",
        m: "mailto: "
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1727333740/Pratiksha_Savandakar_Diploma_Coordinator_vgedsl.jpg",
        name: "Pratiksha Savandkar",
        Post: "Diploma Coordinator",
        l: "",
        i: "",
        m: "mailto: "
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1727334192/Smit_Nagarkar_Diploma_Coordinator_hv74zx.jpg",
        name: "Smit Nagarkar",
        Post: "Diploma Coordinator",
        l: "",
        i: "",
        m: "mailto: "
    },
];

const pastTeam = [{
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726861693/Nidhi_ncyeb3.jpg",
        name: "Nidhi Mhaske ",
        Post: "Secretary",
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726861701/Vaibhav_h0qxie.jpg",
        name: "Vaibhav Ratnaparkhi",
        Post: "Secretary",
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726861696/Pravin_rtg5mk.jpg",
        name: "Pravin Jadhav",
        Post: "Chief Coordinator",
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726861692/Athashree_o1esui.jpg",
        name: "Athashree Patil",
        Post: "Chief Coordinator",
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726861697/Raunak_plov9o.jpg",
        name: "Raunak Jain",
        Post: "Chief Coordinator",
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726861687/Anirudha_gla55k.jpg",
        name: "Anirudha Tathe",
        Post: "Treasurer",
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726861702/Shreenath_dj7ufv.jpg",
        name: "Shreenath Bhosale",
        Post: "Treasurer",
    },


];

const mentor = [{
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726862447/sir_av0lxm.jpg",
        name: "Mandar Tendolkar",
        mail: 'mailto: mvtendolkar@me.vjti.ac.in'
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726862446/mansimaam_hbhipj.jpg",
        name: "Manasi Kulkarni",
        mail: 'mailto: mukulkarni@ce.vjti.ac.in'
    },
    {
        img: "https://res.cloudinary.com/dcir5216l/image/upload/v1726862446/anjalimaam_axgijr.jpg",
        name: "Anjali Dhiware",
        mail: 'mailto: ardhiware@me.vjti.ac.in'
    }
]

const d = {

    teams,
    pastTeam,
    mentor
};
export default d;